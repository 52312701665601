/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */

// import '@kitware/vtk.js/Sources/favicon';

// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Geometry'

import { formatBytesToProperUnit, debounce } from '@kitware/vtk.js/macros'
import HttpDataAccessHelper from '@kitware/vtk.js/IO/Core/DataAccessHelper/HttpDataAccessHelper'
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor'
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray'
import vtkScalarBarActor from '@kitware/vtk.js/Rendering/Core/ScalarBarActor'
import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps'
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction'
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow'
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper'
import vtkURLExtract from '@kitware/vtk.js/Common/Core/URLExtract'
import vtkXMLPolyDataReader from '@kitware/vtk.js/IO/XML/XMLPolyDataReader'
import vtkFPSMonitor from '@kitware/vtk.js/Interaction/UI/FPSMonitor'

// Force DataAccessHelper to have access to various data source
import '@kitware/vtk.js/IO/Core/DataAccessHelper/HtmlDataAccessHelper'
import '@kitware/vtk.js/IO/Core/DataAccessHelper/JSZipDataAccessHelper'

import { ColorMode, ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants'

function vtk() {
    const config = {
        autoInit: false,
        background: [0.96862745, 0.97647059, 1],
        fullScreenRenderWindow: null,
        renderWindow: null,
        renderer: null,
    }

    // import icon from '../img/favicon-32x32.png';

    let scalarBarActor
    let showRenderingOptions = true
    let showRepresentationSelector = true
    let showPresetSelector = true
    let showColorBySelector = true
    let showComponentSelector = true

    global.pipeline = {}

    // Process arguments from URL
    const userParams = vtkURLExtract.extractURLParameters()

    // Background handling
    const selectorClass = 'vtk-selector'

    // background.length === 3 && background.reduce((a, b) => a + b, 0) < 1.5
    //     ? style.dark
    //     : style.light;

    // lut
    const lutName = userParams.lut || 'erdc_rainbow_bright'

    // field
    const field = userParams.field || ''

    // camera
    function updateCamera(camera) {
        ;['zoom', 'pitch', 'elevation', 'yaw', 'azimuth', 'roll', 'dolly'].forEach((key) => {
            if (userParams[key]) {
                camera[key](userParams[key])
            }
            config.renderWindow.render()
        })
    }

    function preventDefaults(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    // ----------------------------------------------------------------------------
    // DOM containers for UI control
    // ----------------------------------------------------------------------------

    const rootControllerContainer = document.createElement('div')
    rootControllerContainer.setAttribute('class', 'vtk-rootController')

    const addDataSetButton = document.createElement('img')
    addDataSetButton.setAttribute('class', 'vtk-button')
    addDataSetButton.addEventListener('click', () => {
        const isVisible = rootControllerContainer.style.display !== 'none'
        rootControllerContainer.style.display = isVisible ? 'none' : 'flex'
    })

    const fpsMonitor = vtkFPSMonitor.newInstance()
    const fpsElm = fpsMonitor.getFpsMonitorContainer()
    fpsElm.classList.add('vtk-fpsMonitor')

    // ----------------------------------------------------------------------------
    // Add class to body if iOS device
    // ----------------------------------------------------------------------------

    const iOS = /iPad|iPhone|iPod/.test(window.navigator.platform)

    if (iOS) {
        document.querySelector('body').classList.add('is-ios-device')
    }

    // ----------------------------------------------------------------------------

    function emptyContainer(container) {
        fpsMonitor.setContainer(null)
        while (container.firstChild) {
            container.removeChild(container.firstChild)
        }
    }

    // ----------------------------------------------------------------------------

    function createViewer(container) {
        config.fullScreenRenderWindow = vtkFullScreenRenderWindow.newInstance({
            background: config.background,
            rootContainer: container,
            containerStyle: { height: '40rem', width: '100%', position: 'relative' },
        })

        config.renderer = config.fullScreenRenderWindow.getRenderer()
        config.renderWindow = config.fullScreenRenderWindow.getRenderWindow()
        config.renderWindow.getInteractor().setDesiredUpdateRate(15)

        container.appendChild(rootControllerContainer)
        container.appendChild(addDataSetButton)

        scalarBarActor = vtkScalarBarActor.newInstance()
        scalarBarActor.setAxisTextStyle({ fontColor: '#000000', fontFamily: 'Source Sans Pro' })
        scalarBarActor.setTickTextStyle({ fontColor: '#000000', fontFamily: 'Source Sans Pro' })
        config.renderer.addActor(scalarBarActor)

        if (userParams.fps) {
            if (Array.isArray(userParams.fps)) {
                fpsMonitor.setMonitorVisibility(...userParams.fps)
                if (userParams.fps.length === 4) {
                    fpsMonitor.setOrientation(userParams.fps[3])
                }
            }
            fpsMonitor.setRenderWindow(config.renderWindow)
            fpsMonitor.setContainer(container)
            config.fullScreenRenderWindow.setResizeCallback(fpsMonitor.update)
        }
    }

    // ----------------------------------------------------------------------------

    function createPipeline(fileName, fileContents, colorLayer) {
        // Create UI
        const presetSelector = document.createElement('select')
        presetSelector.setAttribute('class', selectorClass)
        presetSelector.innerHTML = vtkColorMaps.rgbPresetNames
            .filter((each) => ['2hot', 'erdc_rainbow_bright'].includes(each))
            .map((name) => `<option value="${name}" ${lutName === name ? 'selected="selected"' : ''}>${name}</option>`)
            .join('')

        const representationSelector = document.createElement('select')
        representationSelector.setAttribute('class', selectorClass)
        representationSelector.innerHTML = ['Hidden', 'Points', 'Wireframe', 'Surface', 'Surface with Edge']
            .map(
                (name, idx) =>
                    `<option value="${idx === 0 ? 0 : 1}:${idx < 4 ? idx - 1 : 2}:${
                        idx === 4 ? 1 : 0
                    }">${name}</option>`
            )
            .join('')
        representationSelector.value = '1:2:0'

        const colorBySelector = document.createElement('select')
        colorBySelector.setAttribute('class', selectorClass)

        const componentSelector = document.createElement('select')
        componentSelector.setAttribute('class', selectorClass)
        componentSelector.style.display = 'none'

        const opacitySelector = document.createElement('input')
        opacitySelector.setAttribute('class', selectorClass)
        opacitySelector.setAttribute('type', 'range')
        opacitySelector.setAttribute('value', '100')
        opacitySelector.setAttribute('max', '100')
        opacitySelector.setAttribute('min', '1')

        const labelSelector = document.createElement('label')
        labelSelector.setAttribute('class', 'vtk-rendering-options text-nowrap')
        labelSelector.innerHTML = 'Rendering Options'

        const controlContainer = document.createElement('div')
        controlContainer.setAttribute('class', 'vtk-control')
        controlContainer.appendChild(labelSelector)

        if (showRepresentationSelector) {
            controlContainer.appendChild(representationSelector)
        }


        if (showPresetSelector) {
            controlContainer.appendChild(presetSelector)
        }

        
        if (showPresetSelector) {
            controlContainer.appendChild(presetSelector)
        }

        if (showColorBySelector) {
            controlContainer.appendChild(colorBySelector)
        }

        if (showPresetSelector) {
            controlContainer.appendChild(presetSelector)
        }

        if (showComponentSelector) {
            controlContainer.appendChild(componentSelector)
        }

        //controlContainer.appendChild(opacitySelector);

        while (rootControllerContainer.firstChild) {
            rootControllerContainer.removeChild(rootControllerContainer.firstChild)
        }

        if (showRenderingOptions) {
            rootControllerContainer.appendChild(controlContainer)
        }

        // VTK pipeline
        const vtpReader = vtkXMLPolyDataReader.newInstance()
        vtpReader.parseAsArrayBuffer(fileContents)

        const lookupTable = vtkColorTransferFunction.newInstance()
        const source = vtpReader.getOutputData(0)
        const mapper = vtkMapper.newInstance({
            interpolateScalarsBeforeMapping: false,
            useLookupTableScalarRange: true,
            lookupTable,
            scalarVisibility: false,
        })
        const actor = vtkActor.newInstance()
        const scalars = source.getPointData().getScalars()
        const dataRange = [].concat(scalars ? scalars.getRange() : [0, 1])
        let activeArray = vtkDataArray

        // --------------------------------------------------------------------
        // Color handling
        // --------------------------------------------------------------------

        function applyPreset() {
            const preset = vtkColorMaps.getPresetByName(presetSelector.value)
            lookupTable.applyColorMap(preset)
            lookupTable.setMappingRange(dataRange[0], dataRange[1])
            lookupTable.updateRange()
            config.renderWindow.render()
        }

        applyPreset()
        presetSelector.addEventListener('change', applyPreset)

        // --------------------------------------------------------------------
        // Representation handling
        // --------------------------------------------------------------------

        function updateRepresentation(event) {
            const [visibility, representation, edgeVisibility] = event.target.value.split(':').map(Number)
            actor.getProperty().set({ representation, edgeVisibility })
            actor.setVisibility(!!visibility)
            config.renderWindow.render()
        }

        representationSelector.addEventListener('change', updateRepresentation)

        // --------------------------------------------------------------------
        // Opacity handling
        // --------------------------------------------------------------------

        function updateOpacity(event) {
            const opacity = Number(event.target.value) / 100
            actor.getProperty().setOpacity(opacity)
            config.renderWindow.render()
        }

        opacitySelector.addEventListener('input', updateOpacity)

        // --------------------------------------------------------------------
        // ColorBy handling
        // --------------------------------------------------------------------

        const colorByOptions = [{ value: ':', label: 'Solid color' }].concat(
            source
                .getPointData()
                .getArrays()
                .filter((a) =>
                    ['wss', 'wssg-vec', 'osi', 'rrt', 'DistanceToCenterlines', 'MaximumInscribedSphereRadius'].includes(
                        a.getName()
                    )
                )
                .map((a) => ({
                    label: colorName(a.getName()),
                    value: `PointData:${a.getName()}`,
                }))
            // source
            //     .getCellData()
            //     .getArrays()
            //     .map((a) => ({
            //         label: `c_${a.getName()}`,
            //         value: `CellData:${a.getName()}`,
            //     }))
        )
        colorBySelector.innerHTML = colorByOptions
            .map(
                ({ label, value }) =>
                    `<option value="${value}" ${field === value ? 'selected="selected"' : ''}>${label}</option>`
            )
            .join('')

        function colorName(color) {
            switch (color) {
                case 'wss':
                    return 'WSS'
                case 'wssg-vec':
                    return 'WSSG'
                case 'osi':
                    return 'OSI'
                case 'rrt':
                    return 'RRT'
                case 'DistanceToCenterlines':
                    return 'Distance to Centerlines'
                case 'MaximumInscribedSphereRadius':
                    return 'Maximum Inscribed Sphere Radius'
            }
        }

        function updateColorBy(event) {
            const [location, colorByArrayName] = colorLayer ? ['PointData', colorLayer] : event.target.value.split(':')
            let [colorMode, scalarMode] = colorLayer ? [ColorMode.DIRECT_SCALARS, ScalarMode.USE_POINT_FIELD_DATA] : [ColorMode.DEFAULT, ScalarMode.DEFAULT]

   
            const interpolateScalarsBeforeMapping = location === 'PointData'
            const scalarVisibility = location.length > 0
            if (scalarVisibility) {
                colorMode = colorLayer ? ColorMode.DIRECT_SCALARS : ColorMode.MAP_SCALARS
                scalarMode = location === 'PointData' ? ScalarMode.USE_POINT_FIELD_DATA : ScalarMode.USE_CELL_FIELD_DATA

                if (!colorLayer) {
                    const newArray = source[`get${location}`]().getArrayByName(colorByArrayName)
                    activeArray = newArray
                    const newDataRange = activeArray.getRange()
                    dataRange[0] = newDataRange[0]
                    dataRange[1] = newDataRange[1]


                    const numberOfComponents = activeArray.getNumberOfComponents()
                    if (numberOfComponents > 1) {
                        // always start on magnitude setting
                        if (mapper.getLookupTable()) {
                            const lut = mapper.getLookupTable()
                            lut.setVectorModeToMagnitude()
                        }
                        componentSelector.style.display = 'block'
                        const compOpts = ['Magnitude']
                        while (compOpts.length <= numberOfComponents) {
                            compOpts.push(`Component ${compOpts.length}`)
                        }
                        componentSelector.innerHTML = compOpts
                            .map((t, index) => `<option value="${index - 1}">${t}</option>`)
                            .join('')
                    } else {
                        componentSelector.style.display = 'none'
                    }


                    scalarBarActor.setAxisLabel(colorByArrayName)
                    scalarBarActor.setVisibility(true)
                } else {
                    scalarBarActor.setVisibility(false)
                }
            } else {
                componentSelector.style.display = 'none'
                scalarBarActor.setVisibility(false)
            }            
            mapper.set({
                colorByArrayName,
                colorMode,
                interpolateScalarsBeforeMapping,
                scalarMode,
                scalarVisibility,
            })
            applyPreset()
        }

        colorBySelector.addEventListener('change', updateColorBy)
        updateColorBy({ target: colorBySelector })

        function updateColorByComponent(event) {
            if (mapper.getLookupTable()) {
                const lut = mapper.getLookupTable()
                if (event.target.value === -1) {
                    lut.setVectorModeToMagnitude()
                } else {
                    lut.setVectorModeToComponent()
                    lut.setVectorComponent(Number(event.target.value))
                    const newDataRange = activeArray.getRange(Number(event.target.value))
                    dataRange[0] = newDataRange[0]
                    dataRange[1] = newDataRange[1]
                    lookupTable.setMappingRange(dataRange[0], dataRange[1])
                    lut.updateRange()
                }
                config.renderWindow.render()
            }
        }

        componentSelector.addEventListener('change', updateColorByComponent)

        // --------------------------------------------------------------------
        // Pipeline handling
        // --------------------------------------------------------------------

        actor.setMapper(mapper)
        mapper.setInputData(source)
        config.renderer.addActor(actor)

        scalarBarActor.setScalarsToColors(mapper.getLookupTable())

        // Manage update when lookupTable change
        const debouncedRender = debounce(config.renderWindow.render, 10)
        lookupTable.onModified(debouncedRender, -1)

        // First render
        config.renderer.resetCamera()
        config.renderWindow.render()

        global.pipeline[fileName] = {
            actor,
            mapper,
            source,
            lookupTable,
            renderer: config.renderer,
            renderWindow: config.renderWindow,
        }

        // Update stats
        fpsMonitor.update()
    }

    // ----------------------------------------------------------------------------

    function loadFile(file) {
        const reader = new FileReader()
        reader.onload = function onLoad(e) {
            createPipeline(file.name, reader.result, null)
        }
        reader.readAsArrayBuffer(file)
    }

    // ----------------------------------------------------------------------------

    function load(container, options) {
        config.autoInit = false
        emptyContainer(container)

        if (options.files) {
            createViewer(container)
            let count = options.files.length
            while (count--) {
                loadFile(options.files[count])
            }
            updateCamera(config.renderer.getActiveCamera())
        } else if (options.fileURL) {
            const urls = [].concat(options.fileURL)
            const progressContainer = document.createElement('div')
            progressContainer.setAttribute('class', 'vtk-progress')
            container.appendChild(progressContainer)

            const progressCallback = (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const percent = Math.floor((100 * progressEvent.loaded) / progressEvent.total)
                    progressContainer.innerHTML = `Loading ${percent}%`
                } else {
                    progressContainer.innerHTML = formatBytesToProperUnit(progressEvent.loaded)
                }
            }

            createViewer(container)
            const nbURLs = urls.length
            let nbLoadedData = 0

            /* eslint-disable no-loop-func */
            while (urls.length) {
                const url = urls.pop()
                const name = Array.isArray(userParams.name) ? userParams.name[urls.length] : `Data ${urls.length + 1}`
                HttpDataAccessHelper.fetchBinary(url, {
                    progressCallback,
                }).then((binary) => {
                    nbLoadedData++
                    if (nbLoadedData === nbURLs) {
                        container.removeChild(progressContainer)
                    }
                    createPipeline(name, binary, options.colorLayer)
                    updateCamera(config.renderer.getActiveCamera())
                })
            }
        }
    }

    function initLocalFileLoader(container) {
        const exampleContainer = document.querySelector('.content')
        const rootBody = document.querySelector('body')
        const myContainer = container || exampleContainer || rootBody

        if (myContainer !== container) {
            myContainer.classList.add('vtk-fullscreen')
            rootBody.style.margin = '0'
            rootBody.style.padding = '0'
        } else {
            rootBody.style.margin = '0'
            rootBody.style.padding = '0'
        }

        const fileContainer = document.createElement('div')
        fileContainer.innerHTML = `<div class="${style.bigFileDrop}"/><input type="file" multiple accept=".vtp" style="display: none;"/>`
        myContainer.appendChild(fileContainer)

        const fileInput = fileContainer.querySelector('input')

        function handleFile(e) {
            preventDefaults(e)
            const dataTransfer = e.dataTransfer
            const files = e.target.files || dataTransfer.files
            if (files.length > 0) {
                myContainer.removeChild(fileContainer)
                load(myContainer, { files })
            }
        }

        fileInput.addEventListener('change', handleFile)
        fileContainer.addEventListener('drop', handleFile)
        fileContainer.addEventListener('click', (e) => fileInput.click())
        fileContainer.addEventListener('dragover', preventDefaults)
    }

    // Look at URL an see if we should load a file
    // ?fileURL=https://data.kitware.com/api/v1/item/59cdbb588d777f31ac63de08/download
    if (userParams.url || userParams.fileURL) {
        const exampleContainer = document.querySelector('.content')
        const rootBody = document.querySelector('body')
        const myContainer = exampleContainer || rootBody

        if (myContainer) {
            myContainer.classList.add('vtk-fullscreen')
            rootBody.style.margin = '0'
            rootBody.style.padding = '0'
        }

        load(myContainer, userParams)
    }

    // Auto setup if no method get called within 100ms
    // setTimeout(() => {
    //   if (autoInit) {
    //   }
    // }, 100);

    function initialize() {
        // initLocalFileLoader();
        let geomContainer = document.querySelector('.geom-content')

        if (!geomContainer) {
            return
        }

        let path = '/geom/data/' + geomContainer.dataset.path + '/' + geomContainer.dataset.type
        // let path = geomContainer.dataset.path;
        console.log('geomContainer', geomContainer.dataset.path)
        console.log(path)
        load(geomContainer, { fileURL: path })
    }

    function initializeComponent(container, path, colorLayer, show = {}) {
        const showOptions = {
            showRenderingOptions: true,
            showRepresentationSelector: true,
            showPresetSelector: true,
            showColorBySelector: true,
            showComponentSelector: true,
            ...show,
        }
        load(container, { fileURL: path, colorLayer: colorLayer })
        showRenderingOptions = colorLayer ? false : show.showRenderingOptions
        showRepresentationSelector = show.showRepresentationSelector
        showPresetSelector = show.showPresetSelector
        showColorBySelector = show.showColorBySelector
        showComponentSelector = show.showComponentSelector
    }

    return { initializeComponent, initialize }
}

export default function ({ app }, inject) {
    inject('vtk', (viewer, path, show) => {
        const { initialize } = vtk()
        initialize(viewer, path, show)
    })
    inject('vtkComponent', (viewer, path, colorLayer, show) => {
        const { initializeComponent } = vtk()
        initializeComponent(viewer, path, colorLayer, show)
    })
}
